import {
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTextarea,
} from '@ionic/react';
import { micOutline, paperPlaneOutline, stopOutline } from 'ionicons/icons';
import { useState } from 'react';

interface ChatBarState {
    onMsg: (msg: string) => void,
    onRec: () => void,
    onStop: () => void,
    listening: boolean
}

export default function ChatBar({ onMsg, onRec, onStop, listening }: ChatBarState) {

    const [msg, setMsg] = useState<string>("");

    const emitMsg = () => {
        onMsg(msg);
        setMsg("");
    }

    return (
        <IonToolbar>
            <IonTitle>
                <IonTextarea
                    className='chatInput'
                    onInput={e => {
                        setMsg((e.target as HTMLInputElement).value);
                    }}
                    value={msg}
                    autoGrow={true}
                    onKeyPress={e => {
                        if (e.code === "Enter") {
                            emitMsg();
                        } else {
                            /*
                            let el: any = e.target;
                            var totalHeight = $(el).prop('scrollHeight');

                            console.log("scrollHeight", $(el).prop('scrollHeight'));
                            //console.log("actual", $(el).prop('scrollHeight'));

                            $(el).css({ 'height': totalHeight });
                            */
                        }
                    }}
                    clearOnEdit={true}
                    placeholder="Mensaje..."></IonTextarea>
            </IonTitle>
            <IonButtons slot="end">
                <IonButton className={listening ? "chatRecBottonHide" : "chatRecBottonShow"} onClick={onRec}>
                    <IonIcon slot="icon-only" color="success" icon={micOutline}></IonIcon>
                </IonButton>
                <IonButton className={listening ? "chatRecBottonShow" : "chatRecBottonHide"} onClick={onStop}>
                    <IonIcon slot="icon-only" color="danger" icon={stopOutline}></IonIcon>
                </IonButton>
            </IonButtons>
            <IonButtons slot="end">
                <IonButton onClick={emitMsg}>
                    <IonIcon slot="icon-only" color="primary" icon={paperPlaneOutline}></IonIcon>
                </IonButton>
            </IonButtons>
        </IonToolbar>
    );
}