
import {
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    setupIonicReact,
    IonMenuButton,
    IonFooter,
    IonAccordion,
    IonItem,
    IonLabel
} from '@ionic/react';
import $ from 'jquery';
import { intentParent } from "./types";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

/* Componentes */
import ChatBar from './components/ChatBar';
import Acciones from './components/Acciones';
import { useEffect, useState } from 'react';
import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import SpeechMobile from './components/SpeechMobile';

setupIonicReact();

let wsCnx: any;
let wsConnect: any;
var wsName: string, wsPort: string;
var wsFirst: boolean = true;
var initVoice: () => void;
let webVoice = false;

const App: React.FC = () => {


    const [intents, setIntents] = useState<Array<intentParent>>([]);
    const menuRef = React.useRef<HTMLIonMenuElement>(null);

    const writeMsg = (msg: string) => {
        if (msg) {
            addMsg(msg, 1);
            wsSend({
                acc: "msg",
                msg
            });
        }
    }
    const callIntent = (intent: string, label: string) => {

        menuRef.current?.close();
        if (intent) {
            addMsg("(" + label + ")", 1);
            wsSend({
                acc: "exe",
                intent
            });
        }
    }

    const addMsg = (msg: string, type: number, godown: boolean = true) => {
        let str: string = "";
        msg = msg.replace(/(?:\r\n|\r|\n)/g, '<br>');
        switch (type) {
            case 1: //Me
                str = `
                  <div class="msgMe ion-justify-content-start">
                    <p class="ion-padding">${msg}</p>
                  </div>
              `;
                break;
            case 2: //Ia
                str = `
              <div class="msgIa ion-justify-content-end">
                <p class="ion-padding">${msg}</p>
              </div>
              `;
                break;
            case 3: //Sys
                str = `
              <div class="msgSys ion-justify-content-center">
                <p class="ion-padding">${msg}</p>
              </div>
              `;
                break;
        }
        $("#chatSpace").append(str);
        if (godown) scrollToBottom();
    }

    const scrollToBottom = (query = 'ion-content') => {
        const list: NodeListOf<HTMLIonContentElement> = document.querySelectorAll(query)
        if (list.length) {
            const content: HTMLIonContentElement = list[list.length - 1]
            content.scrollToBottom(500);
        }
    }

    const wsSend = (json: any) => {

        if (wsCnx.readyState === 1) {
            json.trx = uuidv4();
            json.from = wsName;
            json.to = wsPort;

            //console.log("wsCnx", wsCnx);
            console.log("SEND", json);

            wsCnx.send(JSON.stringify(json));

        } else {
            console.log("WS", "Reconectando...");
            wsConnect(() => {
                wsSend(json);
            });
        }
    }

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = (c === 'x') ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    wsConnect = (cb: any) => {
        const endpoint = 'wss://0214mj7cfd.execute-api.us-east-1.amazonaws.com/production';
        if (wsCnx !== undefined) {
            wsCnx.close()
        }
        wsCnx = new WebSocket(endpoint);
        wsCnx.onmessage = (event: any) => {
            var leng;
            if (event.data.size === undefined) {
                leng = event.data.length;
            } else {
                leng = event.data.size;
            }
            var json: any;
            try {
                json = JSON.parse(event.data);
            } catch (ex) { }
            if (json) {
                console.log("JSON", json);
                if (json.acc === "res" && json.res === "me") {
                    wsName = json.me;
                    wsPort = json.port;
                    if (json.port === "") {
                        wsPort = "?";
                        addMsg("No encuentro a Vanny", 3);
                        console.log("Port en blanco");
                        return;
                    } else {
                        addMsg("En línea", 3);
                        if (cb) cb();
                        if (wsFirst) {
                            wsSend({
                                acc: "history"
                            });
                            wsSend({
                                acc: "intents"
                            });
                            if (initVoice) {
                                initVoice();
                            } else {
                                if (browserSupportsSpeechRecognition) {
                                    console.log("Reconocimiento de voz disponible");
                                    //addMsg("Reconocimiento de voz disponible", 3);
                                } else {
                                    console.log("Reconocimiento de voz NO disponible");
                                    //addMsg("Reconocimiento de voz NO disponible", 3);
                                }
                            }
                            wsFirst = false;
                        }
                    }
                } else if (json.acc === "msg") {
                    addMsg(json.msg, 2);
                } else if (json.acc === "res" && json.res === "err" && json.err.message && json.err.message.indexOf("Invalid connectionId") === 0) {
                    addMsg("No encuentro a Vanny", 3);
                    console.log("Invalid connectionId");
                } else if (json.acc === "res" && json.res === "err" && json.err.statusCode === 410) {
                    addMsg("No encuentro a Vanny", 3);
                    console.log("Gone");
                } else if (json.acc === "history") {
                    for (let i = (json.list.length - 1); i >= 0; i--) {
                        let msg = json.list[i];
                        addMsg(msg.msg, msg.type, false);
                    }
                    scrollToBottom();
                } else if (json.acc === "intents") {
                    setIntents(json.list);
                    console.log("intents", intents);
                }
            } else {
                console.log("onmessage. size: " + leng + ", content: " + event.data);
            }
        }

        wsCnx.onopen = function () {
            console.log("WS", "OPEN");
            wsCnx.send(JSON.stringify({
                acc: "me"
            }));
        };

        wsCnx.onclose = function () {
            console.log("onclose.");
        };

        wsCnx.onerror = function () {
            console.log("Error!");
        };
    }


    //Voz
    const { listening, finalTranscript, interimTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    webVoice = browserSupportsSpeechRecognition;
    useEffect(() => {
        if (webVoice) {
            if (finalTranscript !== '') {
                console.log('Got final result:', finalTranscript);
                writeMsg(finalTranscript);
            } else {
                console.log('?:', interimTranscript);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interimTranscript, finalTranscript]);

    if (!webVoice) {
        initVoice = async () => {
            let available;
            try {
                available = await SpeechMobile.available();
            } catch (ex) {
                available = false;
            }
            //addMsg(`Reconocimiento de voz Android ${available ? "" : "NO"} disponible`, 3);
            if (available) {
                let req = await SpeechMobile.requestPermissions();
                console.log("SpeechMobile.requestPermissions", req);
            }
        }
    }

    //Render
    return (<IonApp>
        <>
            <IonMenu ref={menuRef} contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Acciones</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent class="acciones">
                    <Acciones>
                        {intents.map((i, ix) => {
                            return (
                                <div key={"div" + String(ix)}>
                                    <IonAccordion value={i.label}>
                                        <IonItem key={"int" + String(ix)} slot="header" color="light">
                                            <IonLabel>{i.label}</IonLabel>
                                        </IonItem>
                                        <div slot="content">
                                            {i.childs.map((c, cx) => {
                                                return (
                                                    <button key={"bot" + String(cx)} className='intentBot' onClick={() => {
                                                        callIntent(c.intent, c.label);
                                                    }}>{c.label}</button>)
                                            })}
                                        </div>
                                    </IonAccordion>
                                </div>
                            )
                        })}
                    </Acciones>
                </IonContent>
            </IonMenu>
            <IonPage id="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton></IonMenuButton>
                        </IonButtons>
                        <IonTitle>Vanny</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div id="chatSpace"></div>
                </IonContent>
                <IonFooter>
                    <ChatBar
                        listening={listening}
                        onMsg={(msg: string) => {
                            writeMsg(msg);
                        }}
                        onRec={async () => {
                            //addMsg("Comienzo a escuchar", 3);
                            console.log("Comienzo a escuchar");
                            if (webVoice) {
                                SpeechRecognition.startListening({
                                    language: "es-CL"
                                });
                            } else {
                                let text = await SpeechMobile.start({
                                    language: "es-CL",
                                    maxResults: 1,
                                    prompt: "Di algo...",
                                    partialResults: false,
                                    popup: true,
                                });
                                //addMsg(JSON.stringify(text), 3);
                                if (text.matches && text.matches.length > 0) {
                                    writeMsg(text.matches[0]);
                                }
                            }
                        }}
                        onStop={() => {
                            console.log("Dejo de escuchar");
                            //addMsg("Dejo de escuchar", 3);
                            if (webVoice) {
                                SpeechRecognition.stopListening();
                            } else {
                                SpeechMobile.stop();
                            }
                        }}
                    ></ChatBar>
                </IonFooter>
            </IonPage>
        </>
    </IonApp>
    )
};

setTimeout(() => {
    //WS
    wsConnect(() => {

    });
}, 1000);


export default App;
