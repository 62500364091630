import { ReactNode } from 'react';
import { IonAccordionGroup } from '@ionic/react';

type Props = {
    children: ReactNode
  }
  
function Acciones({children} : Props) {
    return (
        <IonAccordionGroup>
            {(children)}
        </IonAccordionGroup>
    );
}
export default Acciones;